/** @jsx jsx */
import { jsx, Container, Grid, Flex, Progress, Button, Slider, Styled} from 'theme-ui'

import {Link} from "gatsby"
import algoliasearch from 'algoliasearch'
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  ClearRefinements,
  RefinementList,
  Configure,
  SortBy
} from 'react-instantsearch-dom'

const searchClient = algoliasearch('S915OEFHS6', '8aba62ee96297963d80ba21c8c903d97');

const Search = () => {

  const Hit = (props) => {
    return (
      <Flex sx={{width: '100%', alignItems: 'center', justifyContent: 'space-between', boxShadow: 'medium', backgroundColor: '#f5f5f5', borderRadius: 'small', py: 4, px: 5}}>
        <Flex sx={{alignItems: 'center', width: '100%'}}>
          {/* <img src={props.hit.images[0].asset.url} sx={{width: '100px'}} /> */}
          <div sx={{ml: 2}}>
            <Styled.h4 sx={{my: 0}}>{props.hit.title}</Styled.h4>
            <Styled.h6 sx={{fontSize: '10px'}}>{props.hit.breedGroups.title}</Styled.h6>
          </div>
        </Flex>
        <div>
          <Button as={Link} variant="nav" to={`/breeds/${props.hit.slug.current}`} sx={{display: 'inline-block', whiteSpace: 'nowrap'}}>Learn More</Button>
        </div>
      </Flex>
    );
  }
  return(    
    <Container>
      <InstantSearch searchClient={searchClient} indexName="Breeds">
        <div>
          <SearchBox />
          {/* <SortBy
            defaultRefinement="Breeds"
            items={[
              { value: 'products_caffeine_desc', label: 'Caffeine Desc.' },
              { value: 'products_caffeine_asc', label: 'Caffeine Asc.' },
            ]}
          /> */}
        </div>
        <Grid columns={[1,1]}>
          <div sx={{display: ['none','none','block']}}>
            <ClearRefinements />
            <RefinementList attribute="breedGroups.title" />
            <Configure hitsPerPage={10} />
          </div>
          <div sx={{width: "100%"}}>
     
              <Hits hitComponent={Hit} />

              <Flex>
                <Pagination />
              </Flex>

          </div>
        </Grid>
      </InstantSearch>
    </Container>
  )
}

export default Search;
