/** @jsx jsx */
import { jsx, Box, Grid, Container, Styled} from 'theme-ui'
import {graphql, Link} from 'gatsby'

import SEO from "../components/seo"
import Search from "../components/Search"

const DogBreeds = ({data}) => {

  const breeds = data.breeds.edges


  return(
    <section>
      <SEO title="Dog Breeds" />
      <Container sx={{px: [5,5,0,0]}}>
        <div sx={{px: 5, textAlign: "center", mt: 6}}>
          <Styled.h1>Dog Breeds</Styled.h1>
          <Styled.p>Here is a list of all of our dog breeds.</Styled.p>
        </div>

        <Search />
      </Container>
    </section>
  )
}

export default DogBreeds

export const query = graphql`
  query {
    breeds: allSanityBreed {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  } 
`

